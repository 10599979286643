@import '../../../theme/helpers/all';

$panel-gutter: 40px;
$margin-label-input: 10px;
$filter-min-height: 36px;
$grey-border: 1px solid #40424a;

$tab-width: 250px;
$num-of-tabs: 4;

.bank-dashboard-container {
  --dashboard-status-sent: #aaaaaa;
  --dashboard-status-maturing: #{$grey-400};
  --dashboard-status-read: #f6a820;
  --dashboard-status-accepted: #77b546;
  --dashboard-status-reinvested-on-behalf: #b1b541;
  --dashboard-status-new-fund: #ddab71;
  --dashboard-status-pending: #76b7ef;
  --dashboard-status-not-interested: #d12c2c;
  --dashboard-status-rfq-in-progress: #f35d00;
  --dashboard-status-unactioned: #249dda;

  .dashboard-container-header {
    padding-right: $tiny;
  }

  .bank-summary-header {
    min-height: 70px;
    padding-bottom: $panel-gutter;
    border-bottom: $grey-border;
    justify-content: space-between;

    div:first-of-type .summary-highlight {
      margin-right: $normal;
    }

    .summary-highlight {
      padding: 0;

      > p {
        margin-bottom: 9px;
      }

      .big-label {
        font-size: 30px;
        margin-bottom: 0;
      }
    }

    .loader {
      padding-top: 10px;
      height: 42px;
    }

    &.is-loading .summary-highlight p ~ div {
      @include includeLoadingSkeleton($include-content: false);
    }

    .counters .summary-highlight {
      margin-left: $panel-gutter;
    }

    button {
      font-size: 1.8rem;
      min-height: 6rem;
      width: 11rem;
      margin-left: $panel-gutter;
    }
  }

  .tab-content {
    padding: 30px;
    border-radius: 5px;
  }

  .new-rfq-button {
    font-size: 1.8rem;
    min-height: 6rem;
    width: 11rem;
    align-self: flex-end;
  }

  .slide-tabs {
    font-size: 1.2rem;
    $icon-size: 22px;
    margin: 0;
    width: $tab-width * $num-of-tabs;

    .nav-tabs {
      padding-right: 0;
      background-color: #40424a;

      > li {
        width: $tab-width;
        @include tabs($num-of-tabs, $tab-width);

        .tag {
          margin: 0;
          position: absolute;
          font-size: 12px;

          > span {
            margin: 0;
            background-color: $curious-blue;
            color: $white;
            padding: 4px 8px;
            border-radius: 50%;
            font-weight: bold;
          }
        }
      }

      .slide {
        top: 50px;
        width: $tab-width;
      }
    }

    .icon {
      vertical-align: middle;
      $icons: dashboard hourglass;
      $dashboard-icons-path: '../../../assets/icons/dashboard/';

      @each $icon in $icons {
        &.#{$icon} {
          @include image-2x($icon, $icon-size, $icon-size, $dashboard-icons-path);
        }
      }
    }
  }

  .dashboard-items .panel-container .panel-filled {
    height: auto;
  }

  .maturities {
    .results > div {
      position: relative;
    }

    .records {
      $section-above-the-maturing-trades-table-height: 540px;
      position: relative;
      max-height: calc(100vh - $section-above-the-maturing-trades-table-height);
      overflow: auto;
    }

    .results {
      background-color: transparent;
      padding: 0;
      margin-bottom: 0;

      .table-responsive {
        overflow: initial;
      }
    }
  }

  .maturities,
  .customers {
    border-radius: $border-radius;

    font-size: 1.4rem;

    .results-presenter {
      margin-top: 30px;
    }

    table.table {
      &.table-striped > tbody > tr:nth-of-type(odd) {
        background-color: #33353c;

        &:hover {
          background-color: #494b54;
        }
      }

      thead {
        tr {
          position: sticky;
          top: 0;
          background-color: var(--background-main-color);
        }
      }

      tr td.actions {
        > div > button {
          min-width: 10rem;
        }

        button {
          border-color: $yellow;
          margin-right: $small;

          > span {
            color: $yellow;
          }

          &:hover {
            > span {
              color: $dark-grey-color;
            }
            background-color: $yellow;
          }

          &.update-ratesheet-button {
            flex: 1;
          }
        }

        .dropdown.disabled {
          visibility: hidden;
        }

        div.btn-group {
          button {
            background-color: $mid-grey;
            border: 0;
            margin-right: 0;
            width: 30px;
            display: flex;
            height: 100%;
            justify-content: center;
            align-items: center;

            > span {
              color: $white;
            }

            &:hover {
              background-color: $grey-dark;
            }
          }

          .dropdown-menu {
            border-radius: 5px;
            padding: 0;

            li {
              border-bottom: 1px solid;

              a {
                padding: $tiny $small;
                border-radius: 5px;
              }

              &.disabled {
                cursor: not-allowed;

                > a {
                  color: $grey-40;
                  pointer-events: none;
                }
              }

              &:last-of-type {
                border-bottom: 0;
              }
            }
          }
        }
      }
    }

    .maturities {
      td.actions {
        width: initial;
      }
    }

    &-header {
      margin: 0 $panel-gutter;
      margin-bottom: 0;
      padding: 26px 0;
      border-bottom: 1px solid $dark-grey-color;

      .value-highlight {
        margin-top: 0;
        margin-left: 0;

        label {
          margin-bottom: 0;
          &:first-of-type {
            margin-left: 0;
          }

          &:last-of-type {
            margin-right: $small;
          }
        }
      }

      .btn .glyphicon {
        padding-left: $tiny;
      }

      &.is-loading .value-highlight label:last-of-type {
        @include includeLoadingSkeleton();
      }

      &-actions button {
        margin-left: $small;
      }
    }

    &-filter {
      padding-bottom: 30px;
      border-bottom: $grey-border;
      justify-content: space-between;

      > div {
        gap: $tiny;
      }

      input,
      button {
        align-self: flex-end;

        &.download-csv-link {
          height: $input-height;
        }

        .glyphicon {
          padding: 0;
        }
      }

      .datepicker-component input {
        font-size: 1.4rem;
      }

      .datepicker-to span,
      .text-ellipsis {
        color: $white;
      }

      .select-filter {
        width: 16rem;
      }

      .customer-filter .form-group {
        margin-bottom: 0;
      }

      .datepicker-to span {
        margin-bottom: $margin-label-input;
      }

      .datepicker-component {
        background-color: $mid-grey;
        border-radius: $border-radius;
        padding-left: $tiny;

        input,
        input ~ span {
          border-bottom: 0;
        }
      }
    }

    &.is-loading {
      .maturing-trades-transactions-chart {
        @include includeLoadingSkeleton($dark: true);
      }
    }
  }
}

.dashboard-status {
  background-color: var(--dashboard-status-unactioned);
  display: inline-block;
  color: $dark-grey-color;
  width: 15rem;
  text-align: center;
  border-radius: 10px;
  font-weight: bold;

  &.pending,
  &.no-ratesheet,
  &.one-day-rollover {
    background-color: var(--dashboard-status-sent);
  }

  &.reinvested-on-behalf {
    background-color: var(--dashboard-status-reinvested-on-behalf);
  }

  &.add-trade-on-behalf {
    background-color: var(--dashboard-status-new-fund);
  }

  &.read {
    background-color: var(--dashboard-status-read);
  }

  &.sent {
    background-color: var(--dashboard-status-sent);
  }

  &.rfq-in-progress {
    background-color: var(--dashboard-status-rfq-in-progress);
  }

  &.rejected,
  &.redeemed,
  &.cancelled-rfq,
  &.redeemed-on-behalf {
    background-color: var(--dashboard-status-not-interested);
  }

  &.reinvested,
  &.accepted {
    background-color: var(--dashboard-status-accepted);
  }
}

.send-offer-dialog {
  $modal-offer-padding: 40px;

  .modal-body {
    min-height: 52rem;
    width: 63rem;
    display: flex;
    flex-direction: column;
    padding: 0;
    padding-top: $modal-offer-padding;

    .dialog-header {
      flex: 0;
      padding-left: $modal-offer-padding;
      padding-right: $modal-offer-padding;
    }

    .react-toggle {
      margin-right: $small;
    }

    .send-offer-dialog-ratesheet {
      flex: 1;
      padding: $modal-offer-padding;
      padding-top: $normal;
      border-top: 1px solid $modal-border-color;
      background-color: $modal-header-background-color;

      > .flex-row {
        margin-bottom: $normal;
      }

      .tenors-grid {
        .tenor {
          background-color: transparent;
          border-width: 1px;
          color: $light-grey;
          height: 9rem;
          padding: 1.6rem;
          font-size: 1.2em;
          text-transform: none;

          &.disabled {
            .empty-percentage {
              font-size: inherit;
              padding-bottom: $tiny;
            }
          }

          .percentage {
            display: flex;
            align-items: center;
            color: $light-grey;
            font-size: 2.2rem;
            font-weight: bold;
            justify-content: flex-end;
          }
        }
      }
    }

    .error {
      width: auto;
    }

    > div:last-of-type {
      margin-bottom: 0;

      p {
        margin-bottom: 0;
      }
    }
  }

  &.td {
    .modal-body {
      min-height: 62rem;
    }
  }
}

.bank-dashboard-dialog .dialog-header {
  margin-bottom: $normal;

  .summary-highlight p ~ div {
    color: $white;
  }
}

.add-new-funds-dialog .modal-content {
  min-width: 80rem;

  .disabled,
  .disabled + .input-group-addon {
    pointer-events: none;
    opacity: 0.5;
  }
}

.update-ratesheet-dialog {
  .modal-content {
    min-width: 120rem;

    .modal-body {
      min-height: 770px;
      padding: $normal;
    }

    .update-ratesheet-container {
      padding: 0;
      margin: 0;

      > :not(.update-ratesheet-presenter) {
        display: none;
      }

      div:last-of-type .results {
        margin-bottom: 0;
      }
    }
  }
}

.bank-unsolicited-ratesheet-toggle-container {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: $small;

  .react-toggle {
    &:not(.react-toggle--checked) {
      .react-toggle-track {
        background-color: var(--background-main-contrast-color);
      }
    }
  }
}
