@import '../../../../../../theme/helpers/all';
@import '@tame-your-theme/scss';

$border-color: darken-theme-color(--background-main-contrast-color, 60%);

.collation-of-ratesheets-container {
  > .react-display-flex {
    gap: $small;
    padding-bottom: $large;

    > div {
      flex: 1;
    }
  }

  .results-presenter {
    .trade-from-rate-column {
      button {
        width: 7rem;
        padding: $hair;
      }

      &.is-best-quote button {
        border-color: $success;

        &:hover:not(.btn-solid-primary) {
          background-color: $success;
          color: $base-black;
        }

        &.btn-solid-primary {
          border-color: var(--primary-color);
        }
      }
    }

    .select-checkbox {
      width: 1rem;
    }
  }

  .unsolicited-ratesheet-filter {
    padding-bottom: 30px;
    justify-content: space-between;

    > div {
      gap: $tiny;
    }

    .form-group {
      margin-bottom: 0;
    }

    input,
    button {
      align-self: flex-end;

      .glyphicon {
        padding: 0;
      }
    }

    .text-ellipsis {
      color: $white;
    }

    .select-filter {
      width: 16rem;
    }

    .btn-solid-primary {
      font-weight: bold;
    }
  }
}

.create-trade-from-rate-popover {
  max-width: initial;
  width: 38rem;

  .actions {
    padding-top: $small;
    padding-right: $small;
    padding-left: $small;
    gap: $small;

    button {
      flex: 1;
    }
  }

  .attention {
    color: $error;
  }

  > .popover-title {
    border-radius: $border-radius;
    background-color: var(--dark-white-background-contrast-color);

    h4 {
      color: var(--dark-white-background-color);
    }

    p {
      color: var(--dark-white-background-color);
      font-weight: 400;
    }
  }

  > .popover-content {
    padding: $small 0;
    background-color: $white;

    h3 {
      font-size: 1.8rem;
      font-weight: 400;
      color: var(--dark-white-background-contrast-color);
      padding-left: $small;
    }

    .create-trade-from-rate-popover-form-header {
      padding-bottom: $normal;

      .imperium-datepicker {
        width: 22rem;
        padding-left: $small;
        flex-direction: row;

        p {
          line-height: 1.1;
          margin-top: 10px;
          font-size: 1.8rem;
          font-weight: 400;
          color: var(--dark-white-background-contrast-color);
        }

        input {
          cursor: pointer;
          padding-bottom: 0;
          background-color: transparent;
          font-size: 1.8rem;
          font-weight: bold;
          padding-left: $hair;
        }

        i {
          top: 9px;
        }

        &.is-custom {
          input,
          i {
            color: var(--primary-color);
          }
        }
      }
    }

    .validated-field {
      min-height: 100px;
      padding-top: 0;
      padding-bottom: 0;

      &.additional-funds {
        min-height: 115px;
      }
    }
  }

  &.is-accepting {
    pointer-events: none;
  }

  &-reinvestment {
    white-space: pre-line;

    h2 {
      font-weight: bold;
      color: var(--dark-white-background-contrast-color);
    }

    p {
      color: var(--dark-white-background-contrast-color);
    }

    > div:first-of-type {
      gap: $small;
      padding-top: $small;
      padding-bottom: $normal;
    }

    > p {
      margin-left: $normal;
      margin-right: $normal;
    }

    &-box {
      border-radius: $border-radius;
      padding: $normal;

      background-color: darken-theme-color(--background-main-contrast-color, 0.1%);
      color: var(--dark-white-background-contrast-color);
      font-weight: bold;
    }

    .actions {
      width: 100%;
      border-top: 1px solid darken-theme-color(--dark-white-background-color, 10%);

      button {
        flex: 1;
      }
    }
  }
}
