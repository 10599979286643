@import '../../theme/helpers/all';

.records {
  padding: 0;

  .is-compliant {
    text-align: center;
    width: 100px;
  }

  .green {
    color: $success;
  }

  .red {
    color: $error;
  }

  .positive-number {
    padding-left: 12px;
  }

  th i {
    padding-right: 5px;
  }

  .column-link {
    cursor: pointer;
  }

  .selected {
    font-weight: bolder;
  }

  .table-responsive {
    border: 0;
  }

  td.action {
    width: 80px;
  }
}
